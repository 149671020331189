.bucket-selection {
	width: 100%;
	height: 100%;
	.questions-container {
		width: 100%;
		.question-data {
			@include flexRow(center, flex-start, 0px);
			border-bottom: 1px solid #f1f3f9;
			background: #fff;
			color: #0c111d;
			font-family: "DM Mono";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			letter-spacing: -0.28px;
			position: relative;
			height: 65px;

			&.header {
				background: #f8f9fc;
				.question,
				.answer {
					h4 {
						color: #000;
						font-family: "DM Mono";
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.5px;
					}
				}
			}
			.sno {
				width: 80px;
				padding: 16px;
				border-right: 1px solid #f1f3f9;
				@include flexRow(center, center, 0px);
				height: inherit;
			}
			.delete {
				width: 80px;
				padding: 16px;
				border-right: 1px solid #f1f3f9;
				@include flexRow(center, center, 0px);
				height: inherit;
				svg {
					cursor: pointer;
				}
			}
			.question,
			.input-breakpoints,
			.answer,
			.categorical-answer {
				width: calc(((100% - 160px) / 2));
				padding: 16px 20px;
				border-right: 1px solid #f1f3f9;
				height: inherit;
				@include flexRow(center, flex-start, 0px);
				.example {
					margin-left: 10px;
					color: #475467;
					font-family: "DM Mono", monospace;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					letter-spacing: -0.96px;
					text-transform: capitalize;
				}
			}

			&.categorical-question-data {
				height: auto;
				align-items: stretch;
				.categorical-answer {
					@include flexCol(flex-start, flex-start, 0px);
					padding: 0px !important;
					.categorical-answer-item {
						width: 100%;
						padding: 16px 20px;
						@include flexRow(stretch, flex-start, 10px);
						border-bottom: 1px solid #f1f3f9;

						&.disabled-bucket {
							background: #f8f9fc;
						}

						&:last-child {
							border-bottom: none;
						}
						.option-dropdown {
							.selected-value-container {
								height: 40px;
								border: none !important;
								.text {
									height: inherit;
									h4 {
										height: inherit;
										max-width: 550px;
										text-overflow: ellipsis;
										overflow: hidden;
										white-space: nowrap;
									}
								}
							}
						}
					}
				}
			}

			.input-breakpoints {
				&.MuiInput-root {
					height: 100%;
					box-shadow: none;
					border-radius: 0px;
					border: none !important;
					background: #fff;
				}
			}

			.question {
				.risk-factor-dropdown {
					.selected-value-container {
						border: none;
						background: #fff;
						&:hover {
							border: none;
							background: #fff;
						}
					}
				}
			}
			&.add-new-buttons {
				width: 100%;
				@include flexRow(center, flex-end, 16px);
				.btn-group {
					padding: 16px;
					width: max-content;
					@include flexRow(center, flex-end, 16px);

					.btn-type-primary {
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 8px;
						background: #25aae5;
						color: #fff;
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 20px; /* 142.857% */
						text-transform: capitalize;
						padding: 12px 16px;
					}

					.btn-type-secondary {
						// position: absolute;
						// top: 12px;
						// right: 16px;
						width: max-content;
						border-radius: 8px !important;
						border: 1px solid #25aae5 !important;
						background: #fff !important;
						color: #25aae5 !important;
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 16px; /* 114.286% */
						padding: 12px 16px;
					}
				}
			}
			&.non-rf-questions {
				.answer {
					width: calc(((100% - 160px) / 2) + 80px);
					padding-right: 30px;
				}
			}
		}
	}
}

.generate-summary-modal {
	@include flexCol(center, center, 16px);
	h4 {
		color: var(--Neutral-1, #23262f);
		font-family: "DM Sans";
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px; /* 133.333% */
		text-transform: capitalize;
		margin-top: 30px;
	}
	.content {
		color: var(--Neutral-2, #718096);
		text-align: center;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 137.5% */
	}

	.btn-type-secondary {
		width: 100%;
		border-radius: var(--radius-md, 8px) !important;
		border: 1px solid #25aae5 !important;
		background: #fff !important;
		color: #25aae5;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		min-height: 40px;
		padding: 8px 36px !important;
	}
}
